<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}
/* Aca inica mi desarrollo  */
.tolttip-message {
  display: none !important;
}

.mx-input {
  height: 3em !important;
}

body {
  background: red !important;
}

.mx-datepicker-range > input {
  height: 3em !important;
}

#header {
  position: relative !important;
}
.btn-search-ubicacion {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-search-ubicacion:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.btn__ingresar__ubicacion {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 40px;
  font-weight: 500 !important;
  font-size: 16px !important;
  letter-spacing: 1px;
}

.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}
.btn-cobertura {
  text-decoration: none;
  padding: 7.5px 12px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-cobertura:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.btn__ingresar__cobertura {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}
.avatar__alicia {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 220px;
}
.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500px;
  color: #000;
}
.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 860px) {
 /*  .container__login {
    // height: 79vh !important;
  } */
  .avatar__alicia {
    display: none;
  }
}

.page-not-available {
  background-color: #4b92fc;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-available > p {
  text-align: center;
}
.page-not-available > p:nth-child(1) {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.page-not-available > p:nth-child(2) {
  color: rgb(34, 59, 102);
}
/* Aca termina */
</style>
<style>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 385px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 385px;
}
.gmap_iframe {
  height: 385px !important;
}
</style>
<template>
  <div>
    <CRow>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Cambio de Dirección</strong>
          </CCardHeader>
          <CCardBody>
            <!-- Escoger el diario -->
            <div class="form-group col-md-7">
              <label for="">Diario</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeCategory($event)"
              >
                <option
                  v-for="item in arrCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <!-- Categoiria -->
            <div class="form-group col-md-7">
              <label for="">Paquetes</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeSubCategory($event)"
              >
                <option
                  v-for="item in arrSubCategory"
                  :value="item.id"
                  :key="item.id"
                >{{ item.name }}
                </option>
              </select>
            </div>
            <!-- seleccionar el paquete el cual me traera la posicion ID -->
            <div class="form-group col-md-7">
              <label for="">Seleccione una suscripción</label>
              <select class="form-control" @change="changeSuscription($event)">
                <option value="">--Seleccionar--</option>
                <option
                  v-for="(item, index) in suscripciones"
                  :value="index + 1"
                  :key="item.id"
                >
                  {{ item.product }} - {{ item.id }}
                </option>
              </select>
            </div>
            <!-- FORMULARIO QUE SE HABILITARA CUANDO ESCOJA UN APSOICIONE -->
            <div>
              <!-- Inputs Escondidos Longitud -->
              <div class="form-group col-md-7">
                <input
                  id="longitud"
                  v-model="markers[0].position.lng"
                  placeholder="Longitud"
                  type="hidden"
                  class="form-control"
                />
              </div>
              <!-- Inputs Escondidos Latitud -->
              <div class="form-group col-md-7">
                <input
                  id="latitud"
                  v-model="markers[0].position.lat"
                  placeholder="Latitud"
                  type="hidden"
                  class="form-control"
                />
              </div>
              <!-- Inputs Escondidos numero document  -->
              <div class="form-group col-md-7">
                <input
                  id="document_number"
                  placeholder="document_number"
                  type="hidden"
                  class="form-control"
                  v-model="nrodoc"
                />
              </div>
              <!-- Inputs Escondidos document type -->
              <div class="form-group col-md-7">
                <input
                  id="document_type"
                  placeholder="document_type"
                  type="hidden"
                  class="form-control"
                  v-model="typedoc"
                />
              </div>
              <!-- Inputs Escondidos nombre rute -->
              <div class="form-group col-md-7">
                <input
                  id="latitud"
                  placeholder="route_name"
                  type="hidden"
                  class="form-control"
                  v-model="route_name"
                />
              </div>
              <!-- Inputs Escondidos posisionId -->
              <div class="form-group col-md-7">
                <input
                  id="positionid"
                  placeholder="positionid"
                  type="hidden"
                  class="form-control"
                  v-model="positionid"
                />
              </div>
              <!-- Inputs Escondidos numero ruta -->
              <div class="form-group col-md-7">
                <input
                  id="route_number"
                  placeholder="route_number"
                  type="hidden"
                  class="form-control"
                  v-model="route_number"
                />
              </div>

              <!-- INPUTS YA VISIBLES -->

              <!-- Nueva direccion de reparto -->
              <div class="form-group col-md-7">
                <label
                  >Nueva Dirección de Reparto
                  <b-icon
                    id="popover-target-1"
                    icon="info-circle-fill"
                    variant="dark"
                    class="mx-2"
                  ></b-icon>
                  <b-popover
                    target="popover-target-1"
                    triggers="hover"
                    placement="right"
                    title="Mover el cursor rojo"
                  >
                    <p>
                      En el mapa, seleccione la ubicacion de su nueva dirección,
                      sea preciso al ubicar su nueva dirección.
                    </p>
                  </b-popover>
                </label>
                <div class="row">
                  <div class="col-md-9 col-sm-8">
                    <GmapAutocomplete
                      id="nuevaDireccion"
                      disabled
                      class="form-control"
                      :position.sync="markers[0].position"
                      @place_changed="setPlace"
                    >
                    </GmapAutocomplete>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <button
                      id="botonNuevaDireccion"
                      disabled
                      class="btn-search-ubicacion btn__ingresar__ubicacion"
                      type="button"
                      @click="usePlace"
                    >
                      Comprobar cobertura
                    </button>
                  </div>
                </div>
              </div>

              <!-- Mapa-->
              <div class="form-group col-md-7">
                <div class="mapouter">
                  <div class="gmap_canvas">
                    <gmap-map
                      :center="center"
                      :zoom="15"
                      style="width: 100%; height: 400px"
                    >
                      <gmap-marker
                        @dragend="updateMaker"
                        :key="index"
                        :position="center"
                        :clickable="true"
                        :draggable="true"
                        @click="center"
                      ></gmap-marker>
                    </gmap-map>
                  </div>
                </div>
              </div>

              <!-- Detalle de direccion -->
              <div class="form-group form-row col-md-7">
                <!-- Mnzana -->
                <div class="col-md-2 col-sm-12">
                  <label>Manzana *</label>
                  <input
                    v-model="manzana"
                    v-on:keyup="disabledKeyManzana($event)"
                    @keypress="validateNumberAndLetter($event)"
                    type="text"
                    class="form-control"
                    placeholder="Ejemplo: 'I6, E' "
                    style="color: black !important"
                    id="inputManzana"
                    disabled=""
                  />
                </div>
                <!-- Lote -->
                <div class="col-md-2 col-sm-12">
                  <label>Lote *</label>
                  <input
                    v-model="lote"
                    v-on:keyup="disabledKeyManzana($event)"
                    @keypress="validateNumber($event)"
                    type="text"
                    class="form-control"
                    placeholder="Ejemplo: '28, 29, 30' "
                    style="color: black !important"
                    id="inputLote"
                    disabled=""
                  />
                </div>
                <!-- Numero domicilio -->
                <div class="col-md-3 col-sm-12">
                  <label>N° Domicilio </label>
                  <input
                    v-model="numero"
                    v-on:keyup="disabledKeyNumero($event)"
                    @keypress="validateNumber($event)"
                    maxlength="4"
                    type="text"
                    class="form-control"
                    placeholder="Ejemplo: '512, 412'"
                    style="color: black !important"
                    id="inputNumero"
                    disabled=""
                  />
                </div>
                <!-- departamento -->
                <div class="col-md-3 col-sm-12">
                  <label>N° Departamento </label>
                  <input
                    v-model="nroDepartamento"
                    @keypress="validateNumber($event)"
                    maxlength="4"
                    type="text"
                    class="form-control"
                    placeholder="Ejemplo: '512, 412'"
                    style="color: black !important"
                    id="inputNroDepartamento"
                    disabled=""
                  />
                </div>
                <!-- Kilometro -->
                <div class="col-md-2 col-sm-12">
                  <label>Kilómetro *</label>
                  <input
                    v-model="kilometro"
                    v-on:keyup="disabledKey($event)"
                    @keypress="validateNumber($event)"
                    maxlength="7"
                    type="text"
                    class="form-control"
                    pattern="[0-9]+"
                    placeholder="Ejemplo: '23, 24 '"
                    style="color: black !important"
                    id="inputKilometro"
                    disabled=""
                  />
                </div>
              </div>
              <!-- Referencia direccion -->
              <div class="form-group form-row col-md-7">
                <div class="col-md-12 col-sm-12">
                  <label>Referencia * </label>
                  <p
                    v-if="isReferencia"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo esta vacio
                  </p>
                  <input
                    v-model="referencia"
                    @keypress="validateNumberAndLetter($event)"
                    maxlength="4"
                    type="text"
                    class="form-control"
                    id="inputReferencia"
                    placeholder="Ejemplo: Av. Jose Carlos Mariategui, Paradero 12"
                    disabled=""
                  />
                </div>
              </div>

              <!-- Forma de entrega -->
              <div class="form-group form-row col-md-7">
                <div class="col-md-4 col-sm-12">
                  <label>Forma de entrega *</label>
                  <p
                    v-if="isFormaEntrega"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo esta vacio
                  </p>
                  <select
                    v-model="formaEntrega"
                    class="form-control"
                    style="color: black !important"
                    id="inputFormaEntrega"
                    disabled
                  >
                    <option value="">--Seleccionar--</option>
                    <option value="DEBAJO PUERTA PRINCIPAL">
                      Debajo de la puerta principal
                    </option>
                    <option value="DEBAJO DE PUERTA DE COCHERA">
                      Debajo de puerta cochera
                    </option>
                    <option value="BUZON">Buzón</option>
                    <option value="RECEPCION">Recepción</option>
                    <option value="AL VIGILANTE">Recepción a vigilante</option>
                    <option value="POR LAS REJAS">Reja a fondo</option>
                  </select>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label>Denominación domicilio *</label>
                  <p
                    v-if="isDenominacion"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo esta vacio
                  </p>
                  <select
                    v-model="denominacion"
                    class="form-control"
                    style="color: black !important"
                    id="inputDenominacion"
                    disabled
                  >
                    <option value="">--Seleccionar--</option>
                    <option value="Casa Familiar">Casa familiar</option>
                    <option value="Casa Playa">Casa de playa</option>
                    <option value="Casa Principal">Casa principal</option>
                    <option value="Oficina">Oficina</option>
                  </select>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label>Información adicional *</label>
                  <p
                    v-if="isInfoAdicional"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo esta vacio
                  </p>
                  <select
                    v-model="infoAdicional"
                    class="form-control"
                    style="color: black !important"
                    id="inputInfoAdicional"
                    disabled
                  >
                    <option value="">--Seleccionar--</option>
                    <option value="CHALET">Chalet</option>
                    <option value="DPTO.">Departamento</option>
                    <option value="ESCUELA">Escuela</option>
                    <option value="INTERIOR">Interior</option>
                    <option value="OFICINA">Oficina</option>
                    <option value="PISO">Piso</option>
                    <option value="TIENDA">Tienda</option>
                  </select>
                </div>
              </div>

              <!-- Info extra -->
              <div class="form-group form-row col-md-7">
                <!-- opcion de cambio temporal -->
                <div class="col-md-4 col-sm-12">
                  <label
                    >¿Cambio temporal?
                    <b-icon
                      id="popover-target-9"
                      icon="info-circle-fill"
                      variant="dark"
                    ></b-icon>
                    <b-popover
                      target="popover-target-9"
                      triggers="hover"
                      placement="right"
                    >
                      Recuerde que todo cambio, <br />
                      comienza despues de dos dias, <br />
                      a la fecha seleccionada.
                    </b-popover>
                  </label>
                  <p
                    v-if="isCambioTemporal"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo esta vacio
                  </p>
                  <select
                    v-model="cambioTemporal"
                    class="form-control"
                    style="color: black !important"
                    id="inputCambioTemporal"
                    disabled
                    @change="changeTemporal($event)"
                  >
                    <option value="">--Seleccionar--</option>
                    <option value="SI">SI</option>
                  </select>
                </div>
                <!-- fecha inicail del cambio -->
                <div class="col-md-4 col-sm-12">
                  <label>Dia de inicio del cambio *</label>
                  <p
                    v-if="isFechaInicial"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo esta vacio
                  </p>
                  <input
                    v-model="fechaInicial"
                    type="date"
                    class="form-control"
                    style="color: black !important"
                    id="inputFechaInicial"
                    disabled
                  />
                </div>
                <!-- Fecha final del cambio -->
                <div class="col-md-4 col-sm-12">
                  <label>Fecha final</label>
                  <p
                    v-if="isFechaFinal"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo esta vacio
                  </p>
                  <input
                    v-model="fechaFinal"
                    type="date"
                    class="form-control"
                    style="color: black !important"
                    id="inputFechaFinal"
                    disabled
                  />
                </div>
              </div>

              <!-- Boton  -->
              <div class="form-group col-md-7">
                <button
                  type="button"
                  id="btn-registrar"
                  class="btn-comercio btn__ingresar"
                  disabled
                  @click="registrarDireccion()"
                >
                  Enviar
                </button>
              </div>

              <br />
              <br />
              <h1
                v-if="messageSave"
                class="alert alert-success alert-dismissible fade show"
              >
                {{ messageSave }}
              </h1>
              <br />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <b-modal
        id="modal-lg"
        size="xs"
        hide-footer
        no-close-on-backdrop
        centered
        hide-header-close
        no-close-on-esc
      >
        <h2 style="text-align: center"><strong>Cambio de dirección</strong></h2>
        <center>
          <p>Escoge tu suscripcion y realiza tu cambio de dirección.</p>
          <p>
            Ingresa tu dirección en el buscador, comprueba la cobertura y
            completa los datos del formulario. Luego dale clic a enviar.
          </p>
        </center>
        <br />
        <b-button
          class="mt-3"
          block
          @click="$bvModal.hide('modal-lg')"
          style="padding: 7px; border-radius: 3px"
          >Listo</b-button
        >
      </b-modal>
    </CRow>

    <img class="avatar__alicia" src="../../assets/img/nueva_alicia_pages_all.png" />
    <img class="avatar__alicia__mobile" src="../../assets/img/nueva_alicia_pages_all.png" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Table from "../base/Table.vue";
import Autocomplete from "vuejs-auto-complete";
import VueGoogleMaps from "vue2-google-maps";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Cards",
  data: function () {
    return {
      /* Aca variables incorporadas */
      fechaInicialFormt: "",
      route_name: "",
      route_number: "",
      availableTags: [],
      temporal: "",
      isLoading: false,
      token: null,
      positionid: null,
      nrodoc: null,
      typedoc: null,
      message: "",
      messageSave: "",
      ruta: "",
      code: "",
      document_type: "dni",
      document_number: "74402882",
      manzana: "",
      isManzana: false,
      lote: "",
      isLote: false,
      numero: "",
      isNumero: false,
      kilometro: "",
      isKilometro: false,
      referencia: "",
      isReferencia: false,
      formaEntrega: "",
      isFormaEntrega: false,
      denominacion: "",
      isDenominacion: false,
      cambioTemporal: "",
      isCambioTemporal: false,
      infoAdicional: "",
      isInfoAdicional: false,
      fechaInicial: "",
      isFechaInicial: false,
      fechaFinal: "",
      isFechaFinal: false,
      nroDepartamento: "",
      coordinatesResponse: {},
      center: { lat: -11.954394, lng: -76.98921 },
      markers: [
        {
          position: { lat: -11.954394, lng: -76.98921 },
        },
      ],
      place: null,
      /* final de variables incorporadas */
      suscripciones: [],
      nameSuscription: "Aún no ha seleccionado una suscripción",
      suscriptionSelected: {},
      categoryId: "",
      subCategoryId: "",
      selectedCategoria: "0",
      positionId: "",
      show: true,
      isCollapsed: true,
      isLoading: false,
      fullPage: true,
      arrCategory: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "El Comercio" },
        { id: "2", name: "Gestión" },
      ],
      arrSubCategory: [{ id: "", name: "--Seleccionar--" }],
    };
  },
  description: "Autocomplete Example (#164)",
  created() {
    this.$getLocation({})
      .then((coordinates) => {
        this.center = coordinates;
      })
      .catch((error) => alert(error));
  },
  components: {
    Loading,
    Table,
    Autocomplete,
  },
  methods: {
    changeCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.categoryId = id;
      vm.isLoading = true;

      if (id == "") {
        vm.arrSubCategory = [{ id: "", name: "--Seleccionar--" }];
      } else if (id == "1") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "1", name: "El Comercio" },
          { id: "2", name: "El Comercio Impreso + Digital" },
          { id: "3", name: "El Comercio Digital" },
        ];
      } else if (id == "2") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "4", name: "Gestión" },
          { id: "5", name: "Gestión Impreso + Digital" },
          { id: "6", name: "Gestión Digital" },
        ];
      }
      vm.suscripciones = [];
      vm.suscriptionSelected = {};
      vm.isLoading = false;
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      vm.isLoading = false;
    },
    changeSubCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.subCategoryId = id;
      vm.isLoading = true;

      vm.suscripciones = [];
      vm.suscriptionSelected = {};
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      if (id == "") {
        vm.isLoading = false;
      } else {
        let data = {
          nro_documento: localStorage.getItem("numeroDocumento"),
          tipo_documento: localStorage.getItem("tipoDocumento"),
          categoria: vm.categoryId,
          subcategoria: id,
          canal: "7",
        };

        axios
          .post(`${process.env.VUE_APP_ROOT_API}/listSuscripciones`, data)
          .then(function (res) {
            vm.isLoading = false;
            if (res.data.data.code == "1") {
              vm.suscripciones = res.data.data.landing;
            } else {
              vm.isLoading = false;
              vm.$fire({
                title: "Información",
                text: "No tiene suscripciones en este paquete.",
                type: "info",
                timer: 4000,
              }).then((r) => {});
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "No tiene suscripciones en este paquete.",
              type: "error",
              timer: 4000,
            }).then((r) => {});
          });
      }
    },
    changeSuscription(event) {
      let vm = this;
      let id = event.target.value;
      vm.isLoading = false;
      if (id) {
        document.getElementById("nuevaDireccion").disabled = false;
        document.getElementById("botonNuevaDireccion").disabled = false;
        vm.positionId = id;
        console.log(vm.positionId);
      } else {
        vm.isLoading = true;
        document.getElementById("nuevaDireccion").disabled = true;
        document.getElementById("botonNuevaDireccion").disabled = true;
      }
    },
    setPlace(place) {
      this.place = place;
      let sessionToken = uuidv4();

      let autocompleteService =
        new VueGoogleMaps.gmapApi.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: this.place,
          sessionToken: sessionToken,
        },
        displaySuggestions
      );
    },
    setDescription(description) {
      this.description = description;
    },
    usePlace() {
      if (this.place) {
        var newPostion = {
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        };
        this.center = newPostion;
        this.markers[0].position = newPostion;
        this.place = null;
        this.sendCoordinates();
      }
    },
    disabledKey: function (event) {
      let vm = this;
      if (vm.kilometro.length > 0) {
        document.getElementById("inputManzana").value = "";
        document.getElementById("inputLote").value = "";
        document.getElementById("inputNumero").value = "";
        document.getElementById("inputManzana").disabled = true;
        document.getElementById("inputLote").disabled = true;
        document.getElementById("inputNumero").disabled = true;
      } else {
        document.getElementById("inputManzana").value = "";
        document.getElementById("inputLote").value = "";
        document.getElementById("inputNumero").value = "";
        document.getElementById("inputManzana").disabled = false;
        document.getElementById("inputLote").disabled = false;
        document.getElementById("inputNumero").disabled = false;
      }
    },
    disabledKeyNumero: function (event) {
      let vm = this;
      if (vm.numero.length > 0) {
        document.getElementById("inputKilometro").value = "";
        document.getElementById("inputLote").value = "";
        document.getElementById("inputManzana").value = "";
        document.getElementById("inputKilometro").disabled = true;
        document.getElementById("inputLote").disabled = true;
        document.getElementById("inputManzana").disabled = true;
      } else {
        document.getElementById("inputKilometro").value = "";
        document.getElementById("inputLote").value = "";
        document.getElementById("inputManzana").value = "";
        document.getElementById("inputKilometro").disabled = false;
        document.getElementById("inputLote").disabled = false;
        document.getElementById("inputManzana").disabled = false;
      }
    },
    disabledKeyManzana: function (event) {
      let vm = this;
      if (vm.manzana.length > 0 || vm.lote.length > 0) {
        document.getElementById("inputKilometro").value = "";
        document.getElementById("inputNumero").value = "";
        document.getElementById("inputKilometro").disabled = true;
        document.getElementById("inputNumero").disabled = true;
      } else {
        document.getElementById("inputKilometro").value = "";
        document.getElementById("inputNumero").value = "";
        document.getElementById("inputKilometro").disabled = false;
        document.getElementById("inputNumero").disabled = false;
      }
    },
    /* TODO:validar numero */
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    validateNumberAndLetter(event) {
      let val = event.key;
      let myRex = new RegExp("^[a-zA-Z0-9 ]+$");
      if (!myRex.test(val)) event.preventDefault();
    },
    /* TODO:validar tamaño del numero */
    validateNumberAndPoint(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9.]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    changeTemporal(event) {
      let vm = this;
      let val = event.target.value;
      if (val == "SI") {
        document.getElementById("inputFechaFinal").disabled = false;
      } else {
        document.getElementById("inputFechaFinal").disabled = true;
      }
    },
    updateMaker: function (event) {
      this.markers[0].position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },
    //funcion para registrar direccion
    sendCoordinates() {
      let vm = this;
      this.token = this.$route.params.token;

      let data = {
        latitud: vm.markers[0].position.lat,
        longitud: vm.markers[0].position.lng,
        token: this.token,
      };

      axios
        .post(
          `${process.env.VUE_APP_ROOT_API}/actualizardireccion-cobertura`,
          data
        )
        .then(function (res) {
          vm.isLoading = false;

          if (res.data.data.code == 1) {
            vm.$fire({
              type: "success",
              title: "Llegamos a la nueva dirección",
              text: res.data.data.message,
              confirmButtonColor: "#3085d6",
              showCloseButton: true,
              confirmButtonText: "Aceptar",
            }).then((result) => {
              if (result.value == true) {
                vm.route_number = res.data.data.cod_cuadrante;
                vm.route_name = res.data.data.ruta;

                document.getElementById("inputManzana").disabled = false;
                document.getElementById("inputLote").disabled = false;
                document.getElementById("inputNumero").disabled = false;
                document.getElementById("inputKilometro").disabled = false;
                document.getElementById("inputReferencia").disabled = false;
                document.getElementById("inputFormaEntrega").disabled = false;
                document.getElementById("inputDenominacion").disabled = false;
                document.getElementById("inputInfoAdicional").disabled = false;
                document.getElementById("inputFechaInicial").disabled = false;
                document.getElementById("inputFechaFinal").disabled = true;
                document.getElementById("inputCambioTemporal").disabled = false;
                document.getElementById(
                  "inputNroDepartamento"
                ).disabled = false;
                document.getElementById("btn-registrar").disabled = false;
              }
            });
          } else if (res.data.data.code == 2) {
            vm.$fire({
              title: "¡Lo sentimos!",
              html: res.data.data.message,
              type: "error",
              timer: 10000,
            }).then((r) => {
              console.log("dismin", r);
              document.getElementById("inputManzana").disabled = true;
              document.getElementById("inputLote").disabled = true;
              document.getElementById("inputNumero").disabled = true;
              document.getElementById("inputKilometro").disabled = true;
              document.getElementById("inputReferencia").disabled = true;
              document.getElementById("inputFormaEntrega").disabled = true;
              document.getElementById("inputDenominacion").disabled = true;
              document.getElementById("inputInfoAdicional").disabled = true;
              document.getElementById("inputFechaInicial").disabled = true;
              document.getElementById("inputFechaFinal").disabled = true;
              document.getElementById("inputCambioTemporal").disabled = true;
              document.getElementById("inputNroDepartamento").disabled = true;
              document.getElementById("btn-registrar").disabled = true;
            });
          } else {
            vm.$fire({
              title: "¡Lo sentimos!",
              html: res.data.data.message,
              type: "error",
              timer: 10000,
            }).then((r) => {
              console.log("dismin", r);
              document.getElementById("inputManzana").disabled = true;
              document.getElementById("inputLote").disabled = true;
              document.getElementById("inputNumero").disabled = true;
              document.getElementById("inputKilometro").disabled = true;
              document.getElementById("inputReferencia").disabled = true;
              document.getElementById("inputFormaEntrega").disabled = true;
              document.getElementById("inputDenominacion").disabled = true;
              document.getElementById("inputInfoAdicional").disabled = true;
              document.getElementById("inputFechaInicial").disabled = true;
              document.getElementById("inputFechaFinal").disabled = true;
              document.getElementById("inputCambioTemporal").disabled = true;
              document.getElementById("inputNroDepartamento").disabled = true;
              document.getElementById("btn-registrar").disabled = true;
            });
          }
        })
        .catch((error) => console.log(error));
    },
    //registrar dieccion
    registrarDireccion() {
      let vm = this;

      vm.isManzana = false;
      vm.isLote = false;
      vm.isNumero = false;
      vm.isKilometro = false;
      vm.isReferencia = false;
      vm.isFormaEntrega = false;
      vm.isDenominacion = false;
      vm.isCambioTemporal = false;
      vm.isInfoAdicional = false;
      vm.isFechaInicial = false;
      vm.isFechaFinal = false;

      let flag = false;

      if (vm.referencia == "") {
        vm.isReferencia = true;
      }

      if (vm.formaEntrega == "") {
        vm.isFormaEntrega = true;
      }

      if (vm.denominacion == "") {
        vm.isDenominacion = true;
      }

      if (vm.cambioTemporal == "") {
        vm.isCambioTemporal = true;
      }

      if (vm.infoAdicional == "") {
        vm.isInfoAdicional = true;
      }

      if (vm.fechaInicial == "") {
        vm.isFechaInicial = true;
      }

      if (
        vm.manzana.length > 0 &&
        vm.manzana.length > 0 &&
        vm.numero == "" &&
        vm.numero == ""
      ) {
        flag = false;
      } else if (
        vm.numero.length > 0 &&
        vm.manzana == "" &&
        vm.lote == "" &&
        vm.kilometro == ""
      ) {
        flag = false;
      } else if (
        vm.kilometro.length > 0 &&
        vm.manzana == "" &&
        vm.lote == "" &&
        vm.numero == ""
      ) {
        flag = false;
      } else {
        flag = true;
      }

      if (flag == true) {
        vm.$fire({
          title: "¡Error!",
          text: "Verifique que se haya completado los campos de: manzana,lote,numero y/o kilometro",
          type: "error",
        }).then((r) => {
          console.log(r);
        });
      } else {
        let data = {
          document_type: localStorage.getItem("tipoDocumento"),
          document_number: localStorage.getItem("numeroDocumento"),
          positionId: vm.positionId,
          token: "",
          route_number: vm.route_number,
          route_name: vm.route_name,
          latitud: vm.markers[0].position.lat,
          longitud: vm.markers[0].position.lng,
          referencia: vm.referencia,
          formaEntrega: vm.formaEntrega,
          denominacion: vm.denominacion,
          infoAdicional: vm.infoAdicional,
          cambioTemporal: vm.cambioTemporal,
          fechaInicial: vm.fechaInicial,
          fechaFinal: vm.fechaFinal,
          manzana: vm.manzana,
          kilometro: vm.kilometro,
          lote: vm.lote,
          numero: vm.numero,
          nroDepartamento: vm.nroDepartamento,
        };

        axios
          .post(
            `${process.env.VUE_APP_ROOT_API}/actualizardireccion-save`,
            data
          )
          .then(function (res) {
            vm.isLoading = false;
            let code = res.data.data.code;

            if (code == 1) {
              vm.$fire({
                title: res.data.data.messageSuccess,
                text: res.data.data.message,
                type: "success",
                timer: 10000,
              }).then((r) => {
                setTimeout(() => {
                  vm.$router.push("/");
                }, 10000);
              });
            } else if (code == 2) {
              vm.$fire({
                title: "¡Lo sentimos!",
                text: res.data.data.message,
                type: "info",
                timer: 5000,
              }).then((r) => {
                console.log("dismin", r);
              });
            } else {
              vm.$fire({
                title: "¡campo incompletos o inválidos!",
                html: res.data.data.message,
                type: "error",
                timer: 10000,
              }).then((r) => {
                console.log("dismin", r);
              });
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "Ocurrio un error en el servidor",
              type: "error",
              timer: 4000,
            }).then((r) => {});
          });
      }
    },
  },
  filters: {
    currency(value) {
      return `S/ ${value}`;
    },
  },
  mounted() {
    let vm = this;
    vm.$bvModal.show("modal-lg");
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
    var now = new Date();

    var day = ("0" + (now.getDate() + 2)).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);

    var today = now.getFullYear() + "-" + month + "-" + day;
    vm.fechaInicial = today;
  },
};
</script>
